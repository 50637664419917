<template>
  <div class="width-limit">
    <TwoColumnTable :items="baseInfo"></TwoColumnTable>
    <el-tabs tab-position="left" style="height: 200px">
      <el-tab-pane label="用户管理">
         <div style="width:500px;height:500px; background:red"></div>
        </el-tab-pane>
      <el-tab-pane label="配置管理"><div style="width:500px;height:900px; background:blue"></div></el-tab-pane>
      <el-tab-pane label="角色管理">角色管理</el-tab-pane>
      <el-tab-pane label="定时任务补偿">定时任务补偿</el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import TwoColumnTable from "@/components/twoColumnTable";
export default {
  components: {
    TwoColumnTable,
  },
  data() {
    return {
      baseInfo: [
        { title: "实施名称", content: "某商务局" },
        { title: "申报对象", content: "个人" },
        { title: "扶持金额", content: "最高20万" },
        { title: "办理时间", content: "2020-07-30至2021-03-23" },
        { title: "咨询电话", content: "0755-89608767" },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
